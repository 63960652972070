
<template>
  <div class="scrollx">
    <title_cat>Commandes en livraison / à retirer</title_cat>
    
    
    
    <div class="tuto" v-if="$parent.commandes && $parent.commandes.sent && $parent.commandes.sent.length === 0">
      <img src="../../assets/img/illu/two/sent.svg" alt="">
      <span>
        Vos commandes à expédier ou à retirer apparaîtrons ici
      </span>
    </div>
    
    <div class="commandes_list" v-else-if="$parent.commandes">
      <div class="commandes_cont">
        
        <commande class="commande" :info_open="true" v-for="n in $parent.commandes.sent" :key="n.id" :data="n" status="sent"></commande>
      </div>
    </div>


    
  </div>
</template>

<script>

import commande from '@/components/bo/commande.vue'
export default {
  name: "commandes_aexpedier",
  components: { commande }
}

</script>